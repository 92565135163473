<template>
  <div class="mallDetails">
    <van-nav-bar title="商品详情" >
      <template #left>
        <van-icon :size="24" @click="revert()" color="#141212" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="recr_swiper">
      <van-swipe class="my-swipe"  :show-indicators="false" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in content.attachments" :key="item.attachmentId" >
          <div class="recr_swiper_item" v-show="item.fileType==1">
            <!--<img :src="item.url" alt="">-->
            <van-image :src="item.url">
              <template v-slot:error>加载失败</template>
            </van-image>
          </div>
          <div class="recr_swiper_item" v-show="item.fileType==2">
            <video width="375px" height="300px" :src="item.url" controls="controls">
            </video>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="recr_swiper_item" >
            <van-image :src="content.cover">
              <template v-slot:error>加载失败</template>
            </van-image>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="mdt_title">
      {{content.name}}
    </div>
    <div class="mdt_moeny">
      <span>￥{{content.sellPrice/100}}</span>
      <span>原价 ￥{{content.shopPrice/100}}</span>
    </div>
    <div class="mdt_txet_list">
      <div class="mdt_txet_list_item" v-show="item" v-for="(item,i) in content.tags.split(',')" :key="i">{{item}}</div>
    </div>
    <div class="mdt_text">
      <img src="./img/gongsi.png" alt="">
      <span>{{content.sellerName}}</span>
    </div>
    <div class="td_tiket_nav">
      <div @click="modify(item)" v-for="(item,i) in content.commodityDetails" :key="i" class="td_tiket_nav_list" :class="item.commodityDetailId==commodityDetailId?'td_tiket_nav_style':'' ">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div v-html="item.content" class="mdtDetails" v-for="(item,i) in content.commodityDetails" :key="i" v-show="item.commodityDetailId==commodityDetailId" >
    </div>
    <shcallComment
      :isPraise="content.isPraise"
      :isCollect="content.isCollect"
      :bizId="params.commodityId"
      :bizType="bizType"
      @praise="praise"
      @collect="collect"
      :star="content.star"
      :collectNum="content.collect"
      :praiseNum="content.praise"
    >
    </shcallComment>
  </div>
</template>

<script src="./mallDetails.js">

</script>

<style scoped src="./mallDetails.css">

</style>
