import {Toast, NavBar, Icon, Swipe, SwipeItem, Image} from 'vant'

export default {
  components:{
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-swipe': Swipe,
    'van-swipe-item': SwipeItem,
    'van-image': Image,
  },
  data () {
    return {
      value: '',
      iconNum:0,

      isLoading: false,
      list: [],
      content:{tags:'',},
      loading: false,
      finished: false,
      params:{
        token:this.$global.token,
        commodityId:""
      },
      showInp:false,//弹幕弹出
      commodityDetailId:'',//分类
      apis:{
        findCommodity:"/commodity/findCommodity",
        userCriticism:'/userCriticism/slicedQueryUserCriticism',//评论列表
        adduserCriticism:'/userCriticism/insertUserCriticism',//添加评论
        addPraise:"/userPraise/addPraise",//点赞
        cancelPraise:"/userPraise/cancelPraise",//取消点赞
        addCollect:'/userCollect/addCollect',//收藏
        cancelMyCollect:"/userCollect/cancelMyCollect"//取消收藏
      },
      commentList:[],//评论列表
      paramsUser:{//评论
        content:'',
        parentId:'',
        bizType:4,
        bizId:"",
      },
      bizType:4,
      commentParams:{
        bizId:"",
        bizType:5,
        pageIndex:1,
        pageSize:10,
      },
    }


  },
  mounted () {
    this.params.commodityId=this.$route.query.commodityId;

    this.commentParams.bizId=this.$route.query.commodityId;
    this.commInit();
  },
  created(){

  },
  methods:{
    praise(){
      this.commInit();
    },
    collect(){
      this.commInit();
    },
    revert(){//返回安卓原生页面或者h5页面
      this.$router.go(-1)
    },

    commInit(){
      const me = this;
      me.$get({
        url:me.apis.findCommodity,
        params:me.params
      }).then(rep=>{
        console.log(rep);
        me.content = rep;
        if(me.content.commodityDetails.length){
          me.commodityDetailId=me.content.commodityDetails[0].commodityDetailId
        }

      })
    },




    modify(obj){//修改显示列表
      this.commodityDetailId=obj.commodityDetailId
    },
    onChange (index) {
      this.iconNum = index
    },
    onLoad () {

    },
    onRefresh () {
      setTimeout(() => {
        Toast('刷新成功');
        this.isLoading = false;
      }, 1000);
    }
  }

}
